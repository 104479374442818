<template>

    <auth-content>
      <el-header :title="$t('modules.saas.kpi.title')" slot="header" />

      <div v-if="!kpiData" class="card">
        <div class="card-body text-center py-5">
          <loader />
        </div>
      </div>

      <div v-if="kpiData && isApp" class="mb-3">

        <div class="row">
          <div v-for="(categoryData, categoryKey) in kpiData[Object.keys(kpiData)[0]]" class="col-xxl-6">
            <div class="card">
              <div class="card-header">
                <h3 class="m-0">{{ getCategoryLabel(categoryKey) }}</h3>
              </div>
              <div class="card-body">
                <div :id="'categoryKey' + categoryKey" class="chart" v-echarts="getBarChartOptions(categoryKey)"></div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div class="mb-3" v-if="kpiData && isApp && kpiDataFormatted">
        <span class="btn btn-outline-secondary mr-2" @click="exportSpreadsheet('csv')"><i class="icon-file-text2"/> {{ $t('modules.process.process.results.export.csv') }}</span>
        <span class="btn btn-outline-secondary mr-2" @click="exportSpreadsheet('xls')"><i class="icon-file-excel"/> {{ $t('modules.process.process.results.export.xls') }}</span>
        <span class="btn btn-outline-secondary mr-2" @click="exportSpreadsheet('png')"><i class="icon-file-picture"/> {{ $t('modules.process.process.results.export.png') }}</span>
        <span class="btn btn-outline-secondary mr-2" @click="exportSpreadsheet('pdf')"><i class="icon-file-pdf"/> {{ $t('modules.process.process.results.export.pdf') }}</span>
      </div>

      <div class="card" v-if="kpiData && isApp">
        <div class="d-flex align-items-center justify-content-between px-3 border-bottom">
          <h2 class="py-2 m-0">{{ $t('modules.saas.kpi.metrics.title') }}</h2>
          <div class='small text-grey'>{{ $t('modules.saas.kpi.metrics.description') }}</div>
        </div>
        <div ref="kpiSpreadsheet" />
      </div>

      <div class="card" v-if="kpiData && expensesData && isApp">
        <div class="d-flex align-items-center justify-content-between px-3 border-bottom">
            <h2 class="py-2 m-0">{{ $t('modules.saas.kpi.expenses.title') }}</h2>
          <div class="d-flex align-items-center">
            <div v-if="expensesSpreadsheetSaved">
              <i class="icon-checkmark-circle text-success ml-3 mr-1" /> <span class="text-grey small">{{ $t('common.saved') }}</span>
            </div>
            <div class='small text-grey' v-else-if="!expensesSpreadsheetModified">{{ $t('modules.saas.kpi.expenses.description') }}</div>
            <button @click="saveExpenses" :disabled="expensesSpreadsheetSaving" v-else class="btn btn-success text-white">
              <span v-if="!expensesSpreadsheetSaving">{{ $t('common.save') }}</span>
              <loader v-else/>
            </button>
          </div>
        </div>
        <div ref="expensesSpreadsheet" />
      </div>

    </auth-content>

</template>

<script>
import V_Echarts from 'vue-echarts-directive';
import _ from 'lodash'
import moment from 'moment'

if (typeof process.env.CORDOVA_PLATFORM === 'undefined') {
  // const webix = require('@/assets/js/webix/webix/webix') // not necessary since its included in index.html
  const spreadsheet = require('@/assets/js/webix/spreadsheet/spreadsheet_debug')
}

import {
  SAAS_GET_KPI,
  SAAS_SAVE_EXPENSES,
} from '../mutations-types'

export default {
  name: 'Index',
  directives: {
    'echarts': V_Echarts,
  },
  data () {
    return {
      expensesToArray: {},
      kpiSpreadsheet: null,
      expensesSpreadsheet: null,
      expensesSpreadsheetSaved: false,
      expensesSpreadsheetSaving: false,
      expensesSpreadsheetModified: false,
      kpiData: null,
      expensesData: null,
    }
  },
  mounted () {
    this.$store.dispatch('saas/' + SAAS_GET_KPI, this.$route.params.world).then(result => {
      this.kpiData = result.kpi_data
      this.expensesData = result.expenses_data
      this.initKpiSpreadsheet()
      this.initExpensesSpreadsheet()
    })
    .catch(error => {
      this.loading = false
      this.notifError(error)
    })
  },
  methods: {
    saveExpensesDebounce: _.debounce(function() {
      this.saveExpenses();
    }, 2000),
    saveExpenses () {

      this.setExpensesToArray()

      if (Object.keys(this.expensesToArray).length == 0) {
        return
      }

      this.expensesSpreadsheetSaving = true

      this.$store.dispatch('saas/' + SAAS_SAVE_EXPENSES, {
        world_id: this.$route.params.world,
        expenses: this.expensesToArray,
      }).then(result => {
        this.kpiData = result.kpi_data
        this.expensesData = result.expenses_data
        this.initKpiSpreadsheet()
        this.initExpensesSpreadsheet()
        this.expensesSpreadsheetSaved = true
        this.expensesSpreadsheetSaving = false
        this.expensesSpreadsheetModified = false
        setTimeout(() => {
          this.expensesSpreadsheetSaved = false
        }, 1500)
      })
      .catch(error => {
        this.expensesSpreadsheetSaving = false
        this.notifError(error)
      })

    },
    getCategoryLabel (categoryKey, subCategoryKey = null) {
      let translationKey = `modules.saas.kpi.${categoryKey}`
      if (subCategoryKey) {
        translationKey+= `.sub.${subCategoryKey}`
      } else {
        translationKey+= `.title`
      }

      let categoryLabel
      if (this.$te(translationKey)) {
        return this.$t(translationKey)
      } else {
        if (subCategoryKey) {
          return subCategoryKey
        } else {
          return categoryKey
        }
      }
    },
    getdateLabel (dateKey) {
      return moment(dateKey, "YYYY-MM").format("MMM YYYY")
    },
    getBarChartOptions (categoryKey) {
      if (!this.kpiData) {
        return []
      }

      let xAxis = []
      _.forEach(this.kpiData, (monthData, monthKey) => {
        xAxis.push(this.getdateLabel(monthKey))
      })

      let series = []
      let legends = []
      _.forEach(this.kpiData[Object.keys(this.kpiData)[0]][categoryKey], (subcategoryData, subcategoryKey) => {
        legends.push(this.getCategoryLabel(categoryKey, subcategoryKey))

        let serie = {
          name: this.getCategoryLabel(categoryKey, subcategoryKey),
          type: 'line',
          data: [],
        }
        _.forEach(this.kpiData, (monthData, monthKey) => {
          serie.data.push(this.kpiData[monthKey][categoryKey][subcategoryKey])
        })

        series.push(serie)
      })

      return {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
            data: legends
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
        type: 'category',
        boundaryGap: false,
          data: xAxis
        },
        yAxis: {},
          series: series,
        }
    },
    initKpiSpreadsheet () {
      if (this.isApp()) {
        return
      }

      this.$nextTick(() => {

        if (!this.kpiSpreadsheet) {
          let sheetHeight = 600

          this.kpiSpreadsheet = webix.ui({
            container: this.$refs.kpiSpreadsheet,
            $scope: this,
            bottombar: false,
            view: "spreadsheet",
            readonly: true,
            height: sheetHeight,
            columnCount: Object.keys(this.kpiData).length + 2,
          })
        }

        if (this.kpiDataFormatted) {
          webix.$$(this.kpiSpreadsheet).parse({
            'data': this.kpiDataFormatted,
            'sizes': [],
            'spans': [],
            'table': {
               "headers":1,
               "gridlines":1,
               "frozenRows":1,
               "frozenColumns":2
            },
            'locked': [],
            'ranges': [],
            'editors': [],
            'filters': [],
            'formats': [],
            'conditions': [],
            'styles': [
              ["wss1", ";;;;;;;bold;;;;;;;;"],
              ["wss2", ";;right;;;;;;;;;;;;;"],
            ],
          })
        }

      })
    },
    initExpensesSpreadsheet () {
      if (this.isApp()) {
        return
      }

      this.$nextTick(() => {

        if (!this.expensesSpreadsheet) {
          let sheetHeight = 600


          this.expensesSpreadsheet = webix.ui({
            container: this.$refs.expensesSpreadsheet,
            $scope: this,
            bottombar: false,
            toolbar: false,
            view: "spreadsheet",
            height: sheetHeight,
            columnCount: Object.keys(this.kpiData).length + 1,
            rowCount: 30, // @todo make dynamic
          })
        }

        webix.$$(this.expensesSpreadsheet).detachEvent("onCellChange")

        if (this.kpiDataFormatted) {
          webix.$$(this.expensesSpreadsheet).parse({
            'data': this.expensesDataFormatted,
            'sizes': [],
            'spans': [],
            'table': {
               "headers":1,
               "gridlines":1,
               "frozenRows":1,
               "frozenColumns":1
            },
            'locked': [],
            'ranges': [],
            'editors': [],
            'filters': [],
            'formats': [],
            'conditions': [],
            'styles': [
              ["wss1", ";;;;;;;bold;;;;;;;;"],
              ["wss2", ";;right;;;;;;;;;;;;;"],
            ],
          })
          webix.$$(this.expensesSpreadsheet).lockCell({ row:1, column:1 }, { row:1, column:Object.keys(this.kpiData).length + 1 }, true)

          webix.$$(this.expensesSpreadsheet).attachEvent("onCellChange", (row, column, value) => {
            this.expensesSpreadsheetModified = true
          })

        }

      })
    },
    exportSpreadsheet (format, spreadsheet = 'kpi') {
      let sheetToExport
      if (spreadsheet == 'kpi') {
        sheetToExport = this.kpiSpreadsheet
      } else {
        sheetToExport = this.expensesSpreadsheet
      }
      if (this.isApp() || !sheetToExport) {
        return
      }
      if (format == 'xls') {
        webix.toExcel(webix.$$(sheetToExport))
      }
      else if (format == 'csv') {
        webix.toCSV(webix.$$(sheetToExport))
      }
      else if (format == 'png') {
        webix.toPNG(webix.$$(sheetToExport))
      }
      else if (format == 'pdf') {
        webix.toPDF(webix.$$(sheetToExport), {
          orientation: "landscape"
        })
      }
    },
    setExpensesToArray () {
      if (this.expensesSpreadsheet) {
        let data = webix.$$(this.expensesSpreadsheet).serialize().data

        this.expensesToArray = {}
        let categoriesArray = {}
        let lineIndex = 0
        let colIndex = 0
        let cellValue = ''
        let monthKey = null

        data.forEach( elem => {
          lineIndex = elem[0]
          colIndex = elem[1]
          cellValue = elem[2]
          monthKey = this.monthsKeyArray[colIndex - 2]

          if (lineIndex > 1 && colIndex == 1) {
            categoriesArray[lineIndex] = cellValue
          }
          if (lineIndex > 1 && colIndex > 1 && monthKey) {
            if (!this.expensesToArray[monthKey]) {
              this.expensesToArray[monthKey] = {}
            }

            let categoryName = categoriesArray[lineIndex] ? categoriesArray[lineIndex] : 'category_' + lineIndex
            if (!isNaN(parseFloat(cellValue)) && isFinite(cellValue)) { // check if number
              this.expensesToArray[monthKey][categoryName] = parseFloat(cellValue)
            }
          }
        })
      }
    },
  },
  computed: {
    monthsKeyArray () {
      if (!this.kpiData) {
        return []
      }
      return Object.keys(this.kpiData)
    },
    expensesDataFormatted () {
      if (!this.kpiData) {
        return []
      }
      let webix = []
      let cellIndex = 1
      let lineIndex, categoryIndex, monthIndex, monthCellIndex

      monthIndex = 1;
      _.forEach(this.kpiData, (monthData, monthKey) => {
        lineIndex = 2
        monthCellIndex = cellIndex + 1
        webix.push([1, monthCellIndex, this.getdateLabel(monthKey), 'wss1'])

        categoryIndex = 1;
        if (this.expensesData[monthKey]) {

          _.forEach(this.expensesData[monthKey], (categoryData, categoryKey) => {
            if (monthIndex == 1) {
              webix.push([lineIndex, 1, categoryKey, 'wss1'])
            }

            webix.push([lineIndex, monthCellIndex, this.expensesData[monthKey][categoryKey] ? this.expensesData[monthKey][categoryKey] : 0, 'wss2'])
            lineIndex++

            categoryIndex++
          })

        }

        monthIndex++
        cellIndex++
      })

      return webix
    },
    kpiDataFormatted () {
      if (!this.kpiData) {
        return []
      }
      let webix = []
      let cellIndex = 1
      let lineIndex, categoryIndex, monthIndex, monthCellIndex

      monthIndex = 1;
      _.forEach(this.kpiData, (monthData, monthKey) => {
        lineIndex = 2
        monthCellIndex = cellIndex + 2
        webix.push([1, monthCellIndex, this.getdateLabel(monthKey), 'wss1'])

        categoryIndex = 1;
        _.forEach(monthData, (categoryData, categoryKey) => {
          if (monthIndex == 1) {
            webix.push([lineIndex, 1, this.getCategoryLabel(categoryKey), 'wss1'])
          }

          _.forEach(categoryData, (subcategoryData, subcategoryKey) => {
            if (monthIndex == 1) {
              webix.push([lineIndex, 2, this.getCategoryLabel(categoryKey, subcategoryKey), 'wss1'])
            }

            webix.push([lineIndex, monthCellIndex, this.kpiData[monthKey][categoryKey][subcategoryKey], 'wss2'])
            lineIndex++
          })

          categoryIndex++
          lineIndex++
        })

        monthIndex++
        cellIndex++
      })

      return webix
    }
  },
}
</script>

<style lang="scss" scoped>
@import '../../../src/assets/js/webix/webix/webix.css';
@import '../../../src/assets/js/webix/spreadsheet/spreadsheet.css';
.chart {
  min-height: 300px;
}
</style>
